.navbar {
  z-index: 2;
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: var(--black);
  padding: 1rem;
}

.container {
  width: 70%;
  max-width: 1440px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#logo {
  text-decoration: none;
  margin-right: 1rem;
}

.nav {
  display: flex;
  flex-direction: row;
  margin: 0;
  margin-block: 0;
  padding-inline: 0;
}

.nav li {
  list-style-type: none;
}

.nav li:not(:first-child) {
  margin-left: 1rem;
}

.nav a {
  font-size: 1rem;
}

@media (max-width: 768px) {
  .container {
    width: 90%;
  }
}
