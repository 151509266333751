@import url('https://fonts.googleapis.com/css2?family=Muli:wght@400;500;700&display=swap');

/* colors */
* {
  --blue: #146de1;
  --light-blue: #2f80ed;
  --lightest-blue: #3986ee;
  --black: #000;
  --dark-grey: #1a1a1a;
  --grey: #303030;
  --light-grey: #7f8c8d;
  --lightest-grey: #95a5a6;
  --white: #f5f6fa;
}

/* global */
img,
svg {
  object-fit: contain;
}

html {
  scroll-behavior: smooth;
}

html,
body {
  height: 100% !important;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-family: 'Muli', 'Roboto', sans-serif;
  font-weight: 400;
  margin: 0;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--black);
  width: 100%;
}

/* utilities */
.accent-blue {
  color: var(--blue);
}

.accent-lightest-blue {
  color: var(--lightest-blue);
}

.link,
.link:link,
.link:visited {
  color: var(--white);
  text-decoration: none;
  outline: 0;
  filter: none;
  transition: color 0.2s ease;
}

.link:hover,
.link:active {
  color: var(--blue);
}

.container {
  width: 70%;
  max-width: 1440px;
  display: flex;
  align-items: center;
}

.row {
  flex-direction: row;
}

.column {
  flex-direction: column;
  align-items: flex-start;
}

/* landing page */
.header {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--black);
  color: var(--white);
}

.intro-text-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 1;
}

.intro-text-box h4 {
  margin-left: 0;
  margin-right: auto;
}

.intro-text {
  font-size: 3.5rem;
  font-weight: 500;
}

.intro-typed-text{
  font-size: 1.6rem;
  padding-left: 0.1875rem; /* intro text and intro type text left align */
}

.header .social-icons {
  position: absolute;
  bottom: 1rem;
}

.social-icons {
  width: 20%;
  display: flex;
  justify-content: space-evenly;
}

.social-icons .link:not(:first-child) {
  margin-left: 1rem;
}

.social-icon {
  height: 2rem;
  width: 2rem !important;
}

.social-icon:not(:first-child) {
  margin-left: 2rem;
}

/* about */
.about {
  height: 100%;
  width: 100%;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  align-items: center;
}

#about-container {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.about-me-info {
  display: flex;
  margin-top: 1rem;
  flex-direction: row;
  width: 100%;
}

.profile-pic-container {
  padding-left: 2rem;
  padding-right: 2rem;
}

.profile-pic {
  border-radius: 50%;
  height: 180px;
  width: 180px;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.5);
}

.info {
  width: 100%;
}

.info p {
  font-size: 20px;
}

.container h1 {
  font-size: 40px;
}

.pro-icons {
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.icon {
  height: 64px;
  width: 64px;
  border-radius: 10%;
  margin-top: 0.5rem;
}

.icon:not(:first-child) {
  margin-left: 1rem;
}

.tech-icons {
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

/* footer */
.footer {
  display: flex;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: var(--black);
  color: var(--white);
  font-size: 12px;
  width: 100%;
  justify-content: center;
}

/* mobile version styles */
@media (max-width: 768px) {
  .container {
    width: 90%;
  }

  .container.row {
    flex-direction: column;
  }

  .about-me-info {
    flex-direction: column;
    align-items: center;
  }

  .profile-pic-container {
    padding-bottom: 2rem;
  }
}
