.container {
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  margin-bottom: 1rem;
  align-items: center;
  width: 100%;
}

#codeTagIconOpenSmall {
  height: 0.75rem;
  width: 0.75rem !important;
}

#codeTagIconCloseSmall {
  height: 1.25rem;
  width: 1.25rem !important;
}

.breakSmall {
  border: 3px dotted var(--black);
  border-style: none none dotted;
  flex-grow: 1;
}
